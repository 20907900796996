import React, { Component } from "react"
import { withPrefix } from "gatsby"

import Carousel, { Modal, ModalGateway } from "react-images"
import axios from "axios"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Banner from "../components/banner-inner"
import Loader from "../components/loader"
import { API_EN } from "../utils/config"

import steering from "../images/steering.png"

import "../styles/page.scss"

class Gallery extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.toggleModal = this.toggleModal.bind(this)
    this.loadMore = this.loadMore.bind(this)

    this.state = {
      modalIsOpen: false,
      selectedIndex: 0,
      items: [],
      visible: 6,
      error: false,
      gallery: null,
    }

    this.fetchData = this.fetchData.bind(this)
  }
  componentDidMount() {
    this._isMounted = true
    // this.setState({
    //     items: galleryArr
    // });

    this.fetchData(["gallery"])
  }
  componentWillUnmount() {
    this._isMounted = false
  }
  fetchData(pages) {
    const get = endpoint => axios.get(`${API_EN}/${endpoint}`)

    return Promise.all(
      pages.map(async page => {
        const { data: item } = await get(`${page}`)
        const Data = {}
        const imgArr = []

        if (item.data) {
          Data.title = item.data[0].post_title
          //Data.images = item.data[0].gallery;
          item.data[0].gallery.map((img, idx) => {
            imgArr.push({ src: img })
          })

          Data.images = imgArr
        }
        return item.data ? Data : null
      })
    ).then(res => {
      if (!res[0]) {
        this.props.navigate(withPrefix(`/404`))
      } else {
        if (this._isMounted) {
          if (res[0]) {
            this.setState({ gallery: res })
          }
        }
      }
    })
  }

  loadMore(e) {
    e.preventDefault()
    this.setState(prev => {
      return { visible: prev.visible + 6 }
    })
  }

  toggleModal = index => {
    this.setState(state => ({
      modalIsOpen: !state.modalIsOpen,
      selectedIndex: index,
    }))
  }
  render() {
    const { modalIsOpen, selectedIndex } = this.state

    if (!this.state.gallery) return <Loader />
    const items = this.state.gallery[0].images
    const title = this.state.gallery[0].title

    return (
      <Layout>
        <SEO title={title} />
        <Banner title={title} />
        <section className="page-section section-gallery">
          <div className="container">
            <div className="section-block">
              <div className="line __right"></div>
              <figure className="placeholder">
                <img src={steering} />
              </figure>
              <div className="gallery-wrap">
                {items.slice(0, this.state.visible).map((gallery, index) => (
                  <div className="gallery-block fade-in" key={index}>
                    <div className="gallery-inner">
                      <a
                        className="gallery-img"
                        onClick={() => this.toggleModal(index)}
                      >
                        <figure>
                          <div
                            className="img"
                            style={{ backgroundImage: `url(${gallery.src})` }}
                          ></div>
                        </figure>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
              <div className="more-wrap text-center">
                {this.state.visible < items.length && (
                  <a href="#" className="more" onClick={this.loadMore}>
                    Load More
                  </a>
                )}
              </div>
            </div>
          </div>
        </section>
        <ModalGateway>
          {modalIsOpen ? (
            <Modal onClose={this.toggleModal}>
              <Carousel
                views={items.slice(0, this.state.visible)}
                currentIndex={selectedIndex}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </Layout>
    )
  }
}

export default Gallery
